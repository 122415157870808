// readings.effects.ts
import { Injectable, Inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, catchError, exhaustMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { SharedService } from '../../shared/shared.service';
import { SweetAlertIcon } from '../../shared/constants/enum';
import { LoaderService } from '../../core/services/loader.service';
import { finalize } from 'rxjs/operators';
import { Validations } from '../../shared/constants/validations';
import {

  CommnicationHistoryFailure,
  CommnicationHistorySuccess,
  getAllCommnicationHistory,
  getAllCommnicationHistoryFailure,
  getAllCommnicationHistorySuccess,
  getArchivedData,
  getArchivedDataFailure,
  getArchivedDataSuccess,
  getCommnicationHistory,
  GetrawData,
  getrawDataFailure,
  getrawDataSuccess,
  getreadings,
  getreadingssFailure,
  getreadingssSuccess,
} from './iot-readings.actions';
import { ReadingsService } from '../../core/services/readings.services';
import { GetAllrawData } from './iot-readings.actions';
import {
  GetAllrawDataSuccess,
  GetAllrawDataFailure,
} from './iot-readings.actions';
import {
  getAllArchivedDataSuccess,
  getAllArchivedDataFailure,
  getAllArchivedData,
} from './iot-readings.actions';
import {
  getAllEb_Dg_Status,
  getAllreadingssFailure,
  getAllreadingssSuccess,
  getAllreadings,
} from './iot-readings.actions';
import {
  getAllElectricStatussSuccess,
  getAllEb_Dg_StatusFailure,
  getAllEb_Dg_StatusSuccess,
} from './iot-readings.actions';
import {
  getAllElectricStatus,
  getAllElectricStatusFailure,
} from './iot-readings.actions';
import {
  getElectricStatus,
  getEb_Dg_StatusFailure,
  getEb_Dg_StatusSuccess,
  getEb_Dg_Status,
} from './iot-readings.actions';
import {
  getElectricStatusFailure,
  getElectricStatussSuccess,
} from './iot-readings.actions';
@Injectable()
export class ReadingsModuleEffects {
  ServiceError = Validations.ServiceError;

  getreadings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getreadings),
      exhaustMap(({ pageIndex, pageSize, searchTerm, filter, orderBy }) => {
        // if (!searchTerm) {
        this.loaderService.setLoading(true);
        // }
        return this.readingservice
          .getreadings(pageIndex, pageSize, searchTerm, filter, orderBy)
          .pipe(
            map((response: any) => {
              this.loaderService.setLoading(false);
              console.log(response);
              if (response && response.statusCode === 200) {
                return getreadingssSuccess({
                  getreadingsResponse: response.data,
                });
              } else {
                return getreadingssFailure({ getreadingsError: response });
              }
            }),
            catchError((error) => {
              console.log(error);
              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(getreadingssFailure({ getreadingsError: error }));
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
      })
    )
  );


  getCommnicationHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCommnicationHistory),
      exhaustMap(({ macAddress, pageIndex, pageSize, searchTerm, filter, orderBy }) => {
        // if (!searchTerm) {
        this.loaderService.setLoading(true);
        // }
        return this.readingservice
          .getMeterCommunicationHistory(macAddress, pageIndex, pageSize, searchTerm, filter, orderBy)
          .pipe(
            map((response: any) => {
              this.loaderService.setLoading(false);
              console.log(response);
              if (response && response.statusCode === 200) {
                return CommnicationHistorySuccess({
                  CommnicationHistoryResponse: response.data,
                });
              } else {
                return CommnicationHistoryFailure({ CommnicationHistoryError: response });
              }
            }),
            catchError((error) => {
              console.log(error);
              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(CommnicationHistoryFailure({ CommnicationHistoryError: error }));
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
      })
    )
  );

  getAllCommnicationHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAllCommnicationHistory),
      exhaustMap(({ macAddress, pageIndex, pageSize, searchTerm, filter, orderBy }) => {
        // if (!searchTerm) {
        this.loaderService.setLoading(true);
        // }
        return this.readingservice
          .getMeterCommunicationHistory(macAddress, pageIndex, pageSize, searchTerm, filter, orderBy)
          .pipe(
            map((response: any) => {
              this.loaderService.setLoading(false);
              console.log(response);
              if (response && response.statusCode === 200) {
                return getAllCommnicationHistorySuccess({
                  getAllCommnicationHistoryResponse: response.data,
                });
              } else {
                return getAllCommnicationHistoryFailure({ getAllCommnicationHistoryError: response });
              }
            }),
            catchError((error) => {
              console.log(error);
              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(getAllCommnicationHistoryFailure({ getAllCommnicationHistoryError: error }));
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
      })
    )
  );

  getAllreadings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAllreadings),
      exhaustMap(({ pageIndex, pageSize, searchTerm, filter, orderBy }) => {
        // if (!searchTerm) {
        this.loaderService.setLoading(true);
        // }
        return this.readingservice
          .getreadings(pageIndex, pageSize, searchTerm, filter, orderBy)
          .pipe(
            map((response: any) => {
              this.loaderService.setLoading(false);
              console.log(response);
              if (response && response.statusCode === 200) {
                return getAllreadingssSuccess({
                  getAllreadingsResponse: response.data,
                });
              } else {
                return getAllreadingssFailure({
                  getAllreadingsError: response,
                });
              }
            }),
            catchError((error) => {
              console.log(error);
              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(getAllreadingssFailure({ getAllreadingsError: error }));
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
      })
    )
  );

  GetrawData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetrawData),
      exhaustMap(({ pageIndex, pageSize, searchTerm, filter, orderBy }) => {
        // if (!searchTerm) {
        this.loaderService.setLoading(true);
        // }
        return this.readingservice
          .getrawData(pageIndex, pageSize, searchTerm, filter, orderBy)
          .pipe(
            map((response: any) => {
              this.loaderService.setLoading(false);
              console.log(response);
              if (response && response.statusCode === 200) {
                return getrawDataSuccess({
                  getrawDataSuccessResponse: response.data,
                });
              } else {
                return getrawDataFailure({ getrawDataeError: response });
              }
            }),
            catchError((error) => {
              console.log(error);
              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(getrawDataFailure({ getrawDataeError: error }));
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
      })
    )
  );

  GetAllrawData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetAllrawData),
      exhaustMap(({ pageIndex, pageSize, searchTerm, filter, orderBy }) => {
        // if (!searchTerm) {
        this.loaderService.setLoading(true);
        // }
        return this.readingservice
          .getrawData(pageIndex, pageSize, searchTerm, filter, orderBy)
          .pipe(
            map((response: any) => {
              this.loaderService.setLoading(false);
              console.log(response);
              if (response && response.statusCode === 200) {
                return GetAllrawDataSuccess({
                  GetAllrawDataSuccessResponse: response.data,
                });
              } else {
                return GetAllrawDataFailure({ GetAllrawDataeError: response });
              }
            }),
            catchError((error) => {
              console.log(error);
              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(GetAllrawDataFailure({ GetAllrawDataeError: error }));
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
      })
    )
  );

  getArchivedData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getArchivedData),
      exhaustMap(({ pageIndex, pageSize, searchTerm, filter, orderBy }) => {
        // if (!searchTerm) {
        this.loaderService.setLoading(true);
        // }
        return this.readingservice
          .getArchivedData(pageIndex, pageSize, searchTerm, filter, orderBy)
          .pipe(
            map((response: any) => {
              this.loaderService.setLoading(false);
              console.log(response);
              if (response && response.statusCode === 200) {
                return getArchivedDataSuccess({
                  getArchivedResponse: response.data,
                });
              } else {
                return getArchivedDataFailure({ getArchivedeError: response });
              }
            }),
            catchError((error) => {
              console.log(error);
              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(getArchivedDataFailure({ getArchivedeError: error }));
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
      })
    )
  );

  getAllArchivedData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAllArchivedData),
      exhaustMap(({ pageIndex, pageSize, searchTerm, filter, orderBy }) => {
        if (!searchTerm) {
          this.loaderService.setLoading(true);
        }
        return this.readingservice
          .getArchivedData(pageIndex, pageSize, searchTerm, filter, orderBy)
          .pipe(
            map((response: any) => {
              this.loaderService.setLoading(false);
              console.log(response);
              if (response && response.statusCode === 200) {
                return getAllArchivedDataSuccess({
                  getAllArchivedResponse: response.data,
                });
              } else {
                return getAllArchivedDataFailure({
                  getAllArchivedeError: response,
                });
              }
            }),
            catchError((error) => {
              console.log(error);
              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(getArchivedDataFailure({ getArchivedeError: error }));
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
      })
    )
  );

  getElectricStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getElectricStatus),
      exhaustMap(({ pageIndex, pageSize, searchTerm, filter, orderBy }) => {
        // if (!searchTerm) {
        this.loaderService.setLoading(true);
        // }
        return this.readingservice
          .getElectricStatusHistory(
            pageIndex,
            pageSize,
            searchTerm,
            filter,
            orderBy
          )
          .pipe(
            map((response: any) => {
              this.loaderService.setLoading(false);
              console.log(response);
              if (response && response.statusCode === 200) {
                return getElectricStatussSuccess({
                  getElectricStatusResponse: response.data,
                });
              } else {
                return getElectricStatusFailure({
                  getElectricStatusError: response,
                });
              }
            }),
            catchError((error) => {
              console.log(error);
              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(
                getElectricStatusFailure({
                  getElectricStatusError: error,
                })
              );
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
      })
    )
  );

  getAllElectricStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAllElectricStatus),
      exhaustMap(({ pageIndex, pageSize, searchTerm, filter, orderBy }) => {
        if (!searchTerm) {
          this.loaderService.setLoading(true);
        }
        return this.readingservice
          .getElectricStatusHistory(
            pageIndex,
            pageSize,
            searchTerm,
            filter,
            orderBy
          )
          .pipe(
            map((response: any) => {
              this.loaderService.setLoading(false);
              console.log(response);
              if (response && response.statusCode === 200) {
                return getAllElectricStatussSuccess({
                  getAllElectricStatusResponse: response.data,
                });
              } else {
                return getAllElectricStatusFailure({
                  getAllElectricStatusError: response,
                });
              }
            }),
            catchError((error) => {
              console.log(error);
              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(
                getAllElectricStatusFailure({
                  getAllElectricStatusError: error,
                })
              );
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
      })
    )
  );

  getEb_Dg_Status$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEb_Dg_Status),
      exhaustMap(({ pageIndex, pageSize, searchTerm, filter, orderBy }) => {
        if (!searchTerm) {
          this.loaderService.setLoading(true);
        }
        return this.readingservice
          .getEb_Dg_History(pageIndex, pageSize, searchTerm, filter, orderBy)
          .pipe(
            map((response: any) => {
              this.loaderService.setLoading(false);
              console.log(response);
              if (response && response.statusCode === 200) {
                return getEb_Dg_StatusSuccess({
                  getEb_Dg_StatusResponse: response.data,
                });
              } else {
                return getEb_Dg_StatusFailure({
                  getEb_Dg_StatusError: response,
                });
              }
            }),
            catchError((error) => {
              console.log(error);
              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(
                getEb_Dg_StatusFailure({
                  getEb_Dg_StatusError: error,
                })
              );
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
      })
    )
  );

  getAllEb_Dg_Status$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAllEb_Dg_Status),
      exhaustMap(({ pageIndex, pageSize, searchTerm, filter, orderBy }) => {
        if (!searchTerm) {
          this.loaderService.setLoading(true);
        }
        return this.readingservice
          .getEb_Dg_History(pageIndex, pageSize, searchTerm, filter, orderBy)
          .pipe(
            map((response: any) => {
              this.loaderService.setLoading(false);
              console.log(response);
              if (response && response.statusCode === 200) {
                return getAllEb_Dg_StatusSuccess({
                  getAllEb_Dg_StatusResponse: response.data,
                });
              } else {
                return getAllEb_Dg_StatusFailure({
                  getAllEb_Dg_StatusError: response,
                });
              }
            }),
            catchError((error) => {
              console.log(error);
              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(
                getAllEb_Dg_StatusFailure({
                  getAllEb_Dg_StatusError: error,
                })
              );
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
      })
    )
  );
  constructor(
    private actions$: Actions,
    private readingservice: ReadingsService,
    private sharedService: SharedService,
    private router: Router,
    private loaderService: LoaderService
  ) { }
}
