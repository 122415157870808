import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { User } from '../../store/Authentication/auth.models';
import { AppConfigData } from '../config/appConfigData';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import { devices } from '../../store/iot-devices/iot-devices.models';
import { detectIE } from '@progress/kendo-angular-grid/utils';

@Injectable({ providedIn: 'root' })
export class DeviceService {
  constructor(private http: HttpClient, private router: Router) {}

  // getdevices(): Observable<any> {

  //   return this.http.get<any>(AppConfigData.getdevicesUrl?pageIndex=1&pageSize=10&search='searchTerm', {}).pipe(
  //     map((res) => {
  //       return res;
  //     }),
  //     catchError((error) => {
  //       console.error('getuserprofile failed:', error);
  //       return throwError(error); // Rethrow the error to propagate it further
  //     })
  //   );
  // }

  getdevices(
    pageIndex: number = 1,
    pageSize: number|undefined,
    searchTerm: string = '',
    filter?: string,
    orderBy?: string
  ): Observable<any> {
    let skipValue, topValue;
    if (pageSize) {
      skipValue = pageIndex > 0 && pageSize ? (pageIndex - 1) * pageSize : 0;
      topValue = pageSize || 10000;
    }
    // let url;
    const url =
      `${AppConfigData.getDevicesUrl}?pageIndex=${pageIndex}` +
      `${pageSize ? `&pageSize=${pageSize}` : ''}` +
      `${pageSize === undefined ? `&criteria=All` : ''}` +
      `${searchTerm ? `&search=${searchTerm}` : ''}` +
      `${filter ? `&${filter}` : ''}` +
      `${orderBy ? `&${orderBy}` : ''}`+ 
       `${skipValue ? `&$skip=${skipValue}` : ''}` +
      `${topValue ? `&$top=${topValue}` : ''}`;
    // if (searchTerm) {
    //   url = `${AppConfigData.getdevicesUrl}?pageIndex=${pageIndex}&pageSize=${pageSize}&search=${searchTerm}`;
    // } else {
    //   url = `${AppConfigData.getdevicesUrl}?pageIndex=${pageIndex}&pageSize=${pageSize}`;
    // }
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getdevices failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  createdevices(deviceData: devices): Observable<any> {
    if(!deviceData.isWiredCommunicationDevice){
      deviceData.gatewayInformationId = null
    }
    
    const updatedDeviceData = { ...deviceData };
    if (!updatedDeviceData.isWiredCommunicationDevice) {
      updatedDeviceData.gatewayInformationId = null;
    }

    // Check if yearOfMake is a valid date string
    if (updatedDeviceData.yearOfMake) {
      const date = new Date(updatedDeviceData.yearOfMake);
      if (!isNaN(date.getTime())) {
        const yearString = date.getFullYear().toString();
        updatedDeviceData.yearOfMake = yearString; // Update yearOfMake in the copied object
      } else {
        console.error('Invalid date format for yearOfMake');
        updatedDeviceData.yearOfMake = ''; // Or any default/fallback value
      }
    }
    return this.http
      .post<any>(AppConfigData.createDevicesUrl, updatedDeviceData)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          console.error('getuserprofile failed:', error);
          return throwError(error); // Rethrow the error to propagate it further
        })
      );
  }

  updateDevice(deviceData: devices, id: any): Observable<any> {
    
    // Create a shallow copy of the deviceData object to avoid modifying the original immutable object
    const updatedDeviceData = { ...deviceData };

    if (!updatedDeviceData.isWiredCommunicationDevice) {
      updatedDeviceData.gatewayInformationId = null;
    }

    // Check if yearOfMake is a valid date string
    if (updatedDeviceData.yearOfMake) {
      const date = new Date(updatedDeviceData.yearOfMake);
      if (!isNaN(date.getTime())) {
        const yearString = date.getFullYear().toString();
        updatedDeviceData.yearOfMake = yearString; // Update yearOfMake in the copied object
      } else {
        console.error('Invalid date format for yearOfMake');
        updatedDeviceData.yearOfMake = ''; // Or any default/fallback value
      }
    }

    return this.http
      .post<any>(`${AppConfigData.updateDevicesUrl}${id}`, updatedDeviceData)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          console.error('updateDevice failed:', error);
          return throwError(error); // Rethrow the error to propagate it further
        })
      );
  }

  deletedevice(id: any): Observable<any> {
    return this.http.post<any>(AppConfigData.deleteDevicesUrl + id, null).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('deleteFaqs failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }
}
